import { useState, useEffect, useRef } from 'react';
import './App.css';
import Message from './components/Message/Message';
import AnimatedCake from './components/AnimatedCake/AnimatedCake';
import Stars from './components/Stars/Stars';
import Clouds from './components/Clouds/Clouds';
import ReplayButton from './components/ReplayButton/ReplayButton';
import chirazAudio from './assets/Chiraz.mp3';

function App() {
  const [message, setMessage] = useState('');
  const [highlightIndex, setHighlightIndex] = useState(0);
  const chirazRef = useRef(new Audio(chirazAudio));

  // Preload audio files
  useEffect(() => {
    chirazRef.current.preload = 'auto';
  }, []);

  const replayChirazSong = () => {
    chirazRef.current.currentTime = 0;
    chirazRef.current.play().catch(error => console.log('Audio play failed:', error));
  };

  useEffect(() => {
    setTimeout(() => {
      chirazRef.current.play().catch(error => console.log('Audio play failed:', error));
    }, 500);
    
    const celebrationMessage = "Joyeux anniversaire ma soeur Malak, Je suis dèsolè pour ce routard, ett ouiii merci pour ton support et bonne anniversaire avec plusieurs Tawfi9at, incha'allah!";
    setMessage(celebrationMessage);
    let index = 0;
    const highlightInterval = setInterval(() => {
      if (index < celebrationMessage.split(' ').length) {
        setHighlightIndex(index);
        index++;
      } else {
        clearInterval(highlightInterval);
      }
    }, 500);

    // Cleanup function to clear interval when component unmounts
    return () => {
      clearInterval(highlightInterval);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="App">
        <div className="surprise-container">
          <ReplayButton onClick={replayChirazSong} />
          <div className="sky-container">
            <Stars />
            <Clouds />
            <AnimatedCake />
          </div>
          <Message message={message} highlightIndex={highlightIndex} />
        </div>
    </div>
  );
}

export default App;
