import React, { useEffect, useState } from 'react';
import './Clouds.css';

const Clouds = () => {
  const [clouds, setClouds] = useState([]);

  useEffect(() => {
    const generateClouds = () => {
      const newClouds = [];
      const numClouds = 8; // Adjust number of clouds

      for (let i = 0; i < numClouds; i++) {
        newClouds.push({
          id: i,
          top: Math.random() * 60 + 10, // Between 10% and 70% from top
          scale: Math.random() * 0.4 + 0.8, // Between 0.8 and 1.2
          delay: -(Math.random() * 20), // Random start position
          duration: Math.random() * 10 + 15 // Between 15 and 25 seconds
        });
      }
      setClouds(newClouds);
    };

    generateClouds();
  }, []);

  return (
    <div className="sky-container">
      {clouds.map(cloud => (
        <div
          key={cloud.id}
          className="cloud"
          style={{
            top: `${cloud.top}%`,
            transform: `scale(${cloud.scale})`,
            animationDelay: `${cloud.delay}s`,
            animationDuration: `${cloud.duration}s`
          }}
        />
      ))}
    </div>
  );
};

export default Clouds;
