import React from 'react';
import './ReplayButton.css';

const ReplayButton = ({ onClick }) => {
  return (
    <button className="replay-button" onClick={onClick}>
      ♫ Replay Song
    </button>
  );
};

export default ReplayButton;
