import React from 'react';
import './Message.css';

const Message = ({ message, highlightIndex }) => {
  return (
    <div className="message-container">
      {message.split(' ').map((word, index) => (
        <span 
          key={index} 
          className={index === highlightIndex ? 'highlighted' : ''}
        >
          {word}{' '}
        </span>
      ))}
    </div>
  );
};

export default Message;
